import React from 'react';
import {TweenMax, Expo} from 'gsap';

let controller,
    controllerWidth;


export default class ColorPaletteController extends React.Component{

    constructor(){
        super();
        this.clickController = this.clickController.bind(this);
    }

    componentDidMount() {
        controller = document.querySelector('.color-palette-controller');
        setTimeout(function(){
            controllerWidth = controller.offsetWidth;
        }, 1000)
    }

    clickController(i) {
        TweenMax.to(controller, 0.6, {x: controllerWidth * i, ease: Expo.easeInOut})
        this.props.changeActive(i);
    }

    render(){
        return(
            <div className="color-palette-section-navigation">
                <span className="color-palette-controller-wrapper">
                    <span className="color-palette-controller"></span>
                </span>
                <ul>
                    {this.props.palettes.map((elem,key) => {
                        return(
                            <li key={key} onClick={this.clickController.bind(ColorPaletteController, key)}>
                                {elem.palette[0].class} Color Palette
                            </li>
                        )
                    })}
                </ul>
            </div>
        )    
    }
}
import React from 'react';
// import SubscribeForm from '../components/newsletter'
import MailchimpSubscribe from "../components/newsletter"


const url = "https://ziqqi.us19.list-manage.com/subscribe/post?u=1e889a4f81d88205a46d73c7b&amp;id=623800b114";
const SimpleForm = () => <MailchimpSubscribe url={url}/>


export default class Footer extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            social: [
                {
                    name: 'Ziqqi Facebook Page',
                    url: 'https://www.facebook.com/ziqqisl/',
                    picto: <svg viewBox="0 0 24 24"><path d="M1.325 0C.593 0 0 .593 0 1.325v21.35C0 23.407.593 24 1.325 24H12.82v-9.281H9.703v-3.633h3.117V8.413c0-3.1 1.893-4.788 4.659-4.788 1.324 0 2.463.098 2.794.142v3.24h-1.907c-1.504 0-1.796.716-1.796 1.765v2.314h3.598l-.469 3.633H16.57V24h6.105c.732 0 1.325-.593 1.325-1.325V1.325C24 .593 23.407 0 22.675 0H1.325z"/></svg>
                },
                {
                    name: 'Ziqqi Instagram Page',
                    url: 'https://www.instagram.com/ziqqisl/',
                    picto: <svg viewBox="0 0 25 25"><path d="M12.5 0C9.105 0 8.68.014 7.346.075c-1.33.06-2.239.272-3.034.581-.822.32-1.52.747-2.214 1.442A6.127 6.127 0 0 0 .656 4.312c-.309.795-.52 1.704-.58 3.034C.013 8.68 0 9.106 0 12.5c0 3.395.014 3.82.075 5.154.06 1.33.272 2.239.581 3.034.32.822.747 1.52 1.442 2.214a6.128 6.128 0 0 0 2.214 1.442c.795.309 1.704.52 3.034.58 1.333.062 1.76.076 5.154.076 3.395 0 3.82-.014 5.154-.075 1.33-.06 2.239-.272 3.034-.581a6.127 6.127 0 0 0 2.214-1.442 6.127 6.127 0 0 0 1.442-2.214c.309-.795.52-1.704.58-3.034.062-1.334.076-1.76.076-5.154 0-3.395-.014-3.82-.075-5.154-.06-1.33-.272-2.239-.581-3.034a6.127 6.127 0 0 0-1.442-2.214A6.126 6.126 0 0 0 20.688.656c-.795-.309-1.704-.52-3.034-.58C16.32.013 15.894 0 12.5 0m0 2.252c3.338 0 3.733.013 5.051.073 1.219.056 1.88.26 2.321.43.584.227 1 .498 1.437.936.438.437.709.853.935 1.437.172.44.375 1.102.43 2.32.061 1.319.074 1.714.074 5.052 0 3.338-.013 3.733-.073 5.051-.056 1.219-.26 1.88-.43 2.321-.227.584-.498 1-.936 1.437a3.873 3.873 0 0 1-1.437.935c-.44.172-1.102.375-2.321.43-1.318.061-1.713.074-5.051.074s-3.733-.013-5.051-.073c-1.219-.056-1.88-.26-2.321-.43-.584-.227-1-.498-1.437-.936a3.873 3.873 0 0 1-.936-1.437c-.17-.44-.374-1.102-.43-2.32-.06-1.319-.073-1.714-.073-5.052 0-3.338.013-3.733.073-5.051.056-1.219.26-1.88.43-2.321.227-.584.498-1 .936-1.437a3.873 3.873 0 0 1 1.437-.935c.44-.172 1.102-.375 2.32-.43 1.319-.061 1.714-.074 5.052-.074"/><path d="M11.957 17.277a4.233 4.233 0 1 1 0-8.467 4.233 4.233 0 0 1 0 8.467m0-10.755a6.522 6.522 0 1 0 0 13.043 6.522 6.522 0 0 0 0-13.043M20.652 5.978a1.63 1.63 0 1 1-3.26 0 1.63 1.63 0 0 1 3.26 0"/></svg>
                },
                {
                    name: 'Ziqqi Youtube Page',
                    url: 'https://www.youtube.com/channel/UC2D538ZiqZ4sZHgbX7vHnrQ',
                    picto: <svg viewBox="0 0 81.94 56.86"><path d="M60.53 0H21.41C0 0 0 6.1 0 21.1v14.66C0 50 3 56.86 21.41 56.86h39.12c16.6 0 21.41-4 21.41-21.1V21.1C81.94 5.31 81.13 0 60.53 0zm-28 39.56v-23L54.62 28z"/></svg>
                },
                {
                    name: 'Ziqqi Twitter Page',
                    url: 'https://twitter.com/ziqqi_4you/',
                    picto: <svg viewBox="0 0 30 24"><path d="M30 2.84a12.437 12.437 0 0 1-3.534.954 6.104 6.104 0 0 0 2.705-3.35 12.523 12.523 0 0 1-3.91 1.47A6.181 6.181 0 0 0 20.769 0c-3.399 0-6.154 2.714-6.154 6.06 0 .474.054.937.159 1.38A17.562 17.562 0 0 1 2.09 1.109a5.946 5.946 0 0 0-.832 3.046 6.035 6.035 0 0 0 2.737 5.044 6.227 6.227 0 0 1-2.788-.76v.075c0 2.936 2.122 5.386 4.936 5.943a6.324 6.324 0 0 1-1.621.213c-.398 0-.782-.039-1.159-.113.784 2.41 3.056 4.162 5.748 4.21a12.469 12.469 0 0 1-7.643 2.59c-.497 0-.986-.03-1.468-.083A17.59 17.59 0 0 0 9.433 24c11.321 0 17.51-9.235 17.51-17.244l-.021-.785A12.19 12.19 0 0 0 30 2.84z"/></svg>
                },
                {
                    name: 'Ziqqi Vimeo Page',
                    url: 'https://vimeo.com/ziqqi',
                    picto: <svg viewBox="0 0 28 25"><path d="M24.989.417c-3.803-1.675-9.287 1.969-9.375 6.053 0 .559.412.794.825.764.148.147.324.265.59.265 3.154.206 1.297 5.201.147 6.612-2.476 3.026-3.98-6.494-4.363-7.905-.737-2.586-2.595-5.26-5.66-4.525-3.45.793-6.25 4.554-7.135 7.846-.03.176-.03.293.059.41-.177.442.471.794.855.5 3.39-2.909 5.13 2.616 6.043 5.172.826 2.292 1.327 4.82 2.949 6.73 4.805 5.759 10.613-2.058 13.502-5.907 2.565-3.526 7.96-13.253 1.563-16.015z"/></svg>
                }
            ]
        }
    }

    render(){
        let d = new Date();
        let date = d.getFullYear();                
        return(
            <footer>
                <div className="row expanded">
                    <div className="xxlarge-5 xxlarge-offset-2 medium-offset-1 medium-6 xsmall-14 xsmall-offset-1 columns white-version">
                        <svg viewBox="0 0 94 22" className="ziqqi-logo"><path d="M27-.005c1.255 1.667 1.879 3.334 2.354 5 .449 1.667.64 3.334.646 5a19.142 19.142 0 0 1-.638 5c-.474 1.667-1.1 3.333-2.362 5-1.262-1.667-1.888-3.333-2.362-5a19.142 19.142 0 0 1-.638-5c.006-1.666.198-3.333.646-5 .476-1.666 1.1-3.333 2.354-5M91-.005c1.255 1.667 1.879 3.334 2.354 5 .448 1.667.64 3.334.646 5 .001 1.667-.19 3.334-.638 5-.474 1.667-1.1 3.333-2.362 5-1.262-1.667-1.888-3.333-2.362-5a19.143 19.143 0 0 1-.638-5 19.39 19.39 0 0 1 .646-5c.476-1.666 1.1-3.333 2.354-5M45.18 4.365c4.324 0 6.865 2.472 6.865 5.95 0 3.48-2.629 5.923-6.835 5.923-4.031 0-6.952-2.213-6.952-5.922 0-3.565 2.862-5.951 6.923-5.951m7.364 14.364c2.98-1.81 4.758-4.782 4.758-8.413 0-6.181-4.673-10.32-12.064-10.32C38.141-.005 33 4.278 33 10.315c0 10.63 14.864 15.419 26 8.31-3.578.73-6.455.103-6.455.103M65.138 10.34c0-3.573 2.798-5.965 6.766-5.965 4.224 0 6.707 2.478 6.707 5.965 0 3.487-2.568 5.936-6.68 5.936-3.938 0-6.793-2.218-6.793-5.936m16.326 6.407c1.458-1.717 2.286-3.904 2.286-6.407 0-6.195-4.567-10.345-11.79-10.345C65.025-.005 60 4.29 60 10.34c0 6.138 4.796 10.345 11.818 10.345 5.17 0 9.167-2.31 15.182.31-.331-1.632-2.763-3.68-5.536-4.248M9.806 15.76L23 2.395S18.382-.2 12.096.007C6.456.193 1.736 2.567.438 3.558c3.896-.983 9.555-.624 13.136 1.028C13.435 4.701 0 17.717 0 17.717s5.594 3.278 11.603 3.278c6.01 0 8.602-1.223 11.323-3.071-5.428.808-10.58-.569-13.12-2.163"/></svg>
                        <h2>Want to receive updates on ZIQQI news?</h2>
                        {/* <SubscribeForm /> */}
                        <SimpleForm />
                        <h3>© <span>{date}</span> ZIQQI. All rights reserved.</h3>
                    </div>
                    <div className="xxlarge-4 xxlarge-offset-3 medium-5 xsmall-14 xsmall-offset-1 columns flex-container">
                        <h2>Follow ZIQQI on social media:</h2>
                        <ul>
                            {this.state.social.map((elem, i) => (
                                <li key={i}>
                                    <a href={elem.url} target="_blank" rel="noopener noreferrer" aria-label={elem.name}>
                                        {elem.picto}
                                    </a>
                                </li>
                            ))}
                        </ul>
                        <a href="https://www.ziqqi.com" target="_blank" rel="noopener noreferrer" className="ziqqi-site">ZIQQI.com</a>
                    </div>
                </div>
            </footer>
        )
    }
}
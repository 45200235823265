import React from 'react'
import { TweenMax, Expo } from 'gsap';

export default class Fullscreen extends React.Component{
    constructor(props){
        super(props);
        this.handleScroll = this.handleScroll.bind(this);
        this.inViewport = false;
        this.fullScreenArray = [];
    }

    componentDidMount(){
        const that = this;
        window.addEventListener('scroll', this.handleScroll);

        document.querySelectorAll(".full-screen-container").forEach(elem => {
            let obj = {};

            obj.elem = elem;
            obj.offsetTop = elem.getBoundingClientRect().top
            obj.inViewport = false;

            TweenMax.set(elem.querySelector(".element-cover"), {
                scale: 1.4
            })

            that.fullScreenArray.push(obj);
        });
    }

    handleScroll(event){
        let scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
        if (this.lastScroll - scrollTop < 0) {
            this.direction = 'down';
        } else if (this.lastScroll - scrollTop > 0) {
            this.direction = 'up';
        }
    
        this.lastScroll = scrollTop <= 0 ? 0 : scrollTop;

        for (let index = 0; index < this.fullScreenArray.length; index++) {
            if(scrollTop + window.innerHeight > this.fullScreenArray[index].offsetTop + window.innerHeight/6 && !this.fullScreenArray[index].inViewport){
                this.fullScreenArray[index].inViewport = true;
                TweenMax.to(this.fullScreenArray[index].elem.querySelector(".element-cover"), 1.4, {
                    scale: 1, 
                    ease: Expo.easeOut
                })
            }
        }
    }

    render(){
        return(
            <section className="full-screen-container"
                style={{
                    position: `relative`
                }}
            >
                <picture className="block-bg-cover">
                    <source srcSet={`${this.props.url}@2x.webp, ${this.props.url}.webp 1x`} type="image/webp" media="(min-width: 1025px)" />
                    <source srcSet={`${this.props.url}@2x.jpg, ${this.props.url}.jpg 1x`} type="image/jpeg" media="(min-width: 1025px)" />
                    <source srcSet={`${this.props.url}.webp`} type="image/webp" media="(max-width: 1024px)" />
                    <source srcSet={`${this.props.url}.jpg`} type="image/jpeg" media="(max-width: 1024px)" />
                    <img className="element-cover" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAzIDInPjwvc3ZnPg==" alt={this.props.alt} title={this.props.alt} />
                </picture>
            </section>
        )
    }
}

import React from "react";
import PropTypes from "prop-types";
import jsonp from "jsonp";
import toQueryString from "to-querystring";
import SimpleForm from "../components/simple-form";

const getAjaxUrl = url => url.replace("/post?", "/post-json?");

class MailchimpSubscribe extends React.Component {

  constructor(){
    super()
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  state = {
    status: null,
    message: null
  };

  validateEmail(email) {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(email);
  }

  handleInputChange(e){
    this.setState({
      value: e.target.value
    });
    if(this.validateEmail(this.state.value)){
      document.querySelector(".form-newsletter button").classList.remove("disabled");
    } else {
      document.querySelector(".form-newsletter button").classList.add("disabled");
    }
  }
  
  subscribe = data => {
    const params = toQueryString(data);
    const url = getAjaxUrl(this.props.url) + "&" + params;
    this.setState(
      {
        status: "sending",
        message: null
      },
      () =>
        jsonp(
          url,
          {
            param: "c"
          },
          (err, data) => {
            if (err) {
              this.setState({
                status: "error",
                message: err
              });
            } else if (data.result !== "success") {
              this.setState({
                status: "error",
                message: data.msg
              });
            } else {
              this.setState({
                status: "success",
                message: data.msg
              });
            }
          }
        )
    );
  };
  render() {
    return this.props.render({
      subscribe: this.subscribe,
      status: this.state.status,
      message: this.state.message,
      handleInputChange: this.handleInputChange
    });
  }
}

MailchimpSubscribe.propTypes = {
  render: PropTypes.func,
  url: PropTypes.string.isRequired
};

MailchimpSubscribe.defaultProps = {
  render: ({ subscribe, status, message, handleInputChange }) => (
    <SimpleForm
      className="form-newsletter"
      status={status}
      message={message}
      change={handleInputChange}
      onSubmitted={formData => subscribe(formData)}
    />
  )
};

export default MailchimpSubscribe;
import React from 'react';
import ColorPaletteController from '../components/color-palette-controller'

export default class ColorPalette extends React.Component{
    componentDidMount(){
        document.querySelector('.main-palette').classList.add('active');
    }

    changeActive(i) {
        document.querySelector('.palette.active').classList.remove('active');
        document.querySelectorAll('.palette').forEach((element, key) => {
            if(key === i){
                element.classList.add('active');
            }
        });
    }

    render(){
        let string = '';
        let controller;
        let total = this.props.palettes.length;

        if(total > 1){
            controller = <ColorPaletteController changeActive={this.changeActive} palettes={this.props.palettes}/>;
        } else{
            controller = ' ';
        }

        return(
            <section className="color-palette-section" data-target data-value={this.props.value} data-intersect data-intersect-color="black-and-white">
              <div className="row expanded align-center">
                <div className="xxlarge-12 medium-14 columns flex-container">
                    <h2>Color palette</h2>
                    <div className="palette-container">
                        {this.props.palettes.map((elem,key) => {
                            return(
                                <article className={`palette ${elem.palette[0].class}-palette`} key={key}>
                                    <div className={`row expanded xxlarge-up-${elem.palette.length} xsmall-up-1`}>
                                        {elem.palette.map((element, key) => {
                                            return(
                                                <div className="columns" key={key}>
                                                    <div className="palette-square" key={key} style={{
                                                        backgroundColor: `${element.background}`
                                                    }}>
                                                        <h3 style={{
                                                            color: `${element.colorElements}`
                                                        }}>{element.color}</h3>
                                                        <ul>
                                                            {element.properties.map(function(el, key) {
                                                                if(Object.keys(el).includes('embroidery')){
                                                                    string = 'embroidery-thread';
                                                                } else if(Object.keys(el).includes('colored')){
                                                                    string = 'colored-film';
                                                                } else {
                                                                    string = Object.keys(el);
                                                                }
                                                                return(
                                                                    <li key={key} style={{
                                                                        color: `${element.colorElements}`
                                                                    }}>
                                                                        <span>{string}</span> {Object.values(el)}
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </article>
                            )
                        })}
                    </div>
                    {controller}
                </div>
              </div>
            </section>
        )    
    }
}


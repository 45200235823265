import React from 'react';


export default class Button extends React.Component {
    render(){
        return (
            <React.Fragment>
                <div className={this.props.class}>
                    <a href="../ZIQQI-Brand-Kit.zip" target="_blank" rel="noopener noreferrer">
                        <div className="button-wrapper">
                            <span className="circle">
                                <svg viewBox="0 0 15 15">
                                    <path d="M.54 13.93a.54.54 0 1 0 0 1.07h13.93a.54.54 0 0 0 0-1.07zM7.49 0A.53.53 0 0 0 7 .54V10L3.6 6.59a.54.54 0 1 0-.76.76l4.28 4.28h.55l4.28-4.28a.54.54 0 0 0 0-.76.5.5 0 0 0-.4-.16.52.52 0 0 0-.37.16L8 10V.54A.54.54 0 0 0 7.5 0z"/>
                                </svg>
                            </span>
                            <button>
                                <span>{this.props.name}</span>
                            </button>
                        </div>
                    </a>
                    {(this.props.caption !== null) ? <h4 className="button-caption">{this.props.caption}</h4> : ''}
                </div> 
            </React.Fragment>
        )
    }
}
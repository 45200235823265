import React from 'react';
import Arrow from "../images/arrow.svg";

const scrollHelper = () => {
    return(
        <React.Fragment>
            <div className="scroll-helper-container"
                style={{
                    width: `20px`,
                    position: `absolute`,
                    display: `flex`,
                    alignItems: `center`,
                    flexDirection: `column`,
                    overflow: `hidden`,
                }}
            >
                <span
                   style={{
                       fontFamily: `EquipExtended`,
                       fontWeight: `500`,
                       fontSize: `9px`,
                       textTransform: `uppercase`,
                       letterSpacing: `2.7px`,
                       writingMode: `vertical-lr`,
                       transform: `rotate(180deg)`,
                   }}
                >Scroll</span>
                <div className="scroll-helper-arrow-container"
                    style={{
                        marginTop: `17px`,
                        overflow: `hidden`,
                    }}
                >
                    <Arrow 
                        style={{
                            width: `11px`,
                            height: `58px`,
                            willChange: `transform`,
                            animation: `scrollHelperMove 1.4s infinite ease-in-out`,
                        }}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export default scrollHelper
import React from 'react';
import { TweenMax, Elastic } from 'gsap';

export default class SubMenu extends React.Component{
    constructor(props){
        super();

        this.clickNav = this.clickNav.bind(this);
        this.clickMenuItem = this.clickMenuItem.bind(this);
        this.handleScroll= this.handleScroll.bind(this);
        this.state = {
            maxHeight: 0,
            minHeight: 0,
            openedMenu: false,
            menuItems: []
        }
        this.allSections = [];
        this.direction = '';
        this.lastScroll = 0;
        this.activeSectionIndex = 0;
        this.sectionsIntersect = [];
    }

    componentWillMount(){
        this.setState({
            menuItems: this.state.menuItems.concat(this.props.list)
        });
    }

    componentDidMount(){
        const navUl = document.querySelector("nav ul");
        const that = this;

        this.setState({
            maxHeight: this.getMaxHeight(),
            minHeight: this.getMinHeight()
        });

        TweenMax.set(navUl, {height: this.getMinHeight()});
        window.addEventListener('scroll', this.handleScroll);

        setTimeout(function(){
            document.querySelectorAll("section[data-target=true]").forEach(element => {
                let obj = {};
                const rect = element.getBoundingClientRect();
    
                obj.elem = element;
                obj.value = element.getAttribute("data-value");
                obj.offsetTop = rect.top + document.body.scrollTop - document.querySelector("nav").offsetHeight;
                if(element.getAttribute("data-value") === 'Typography'){
                    obj.elemHeight = element.offsetHeight*2;
                } else {
                    obj.elemHeight = element.offsetHeight;
                }
                obj.active = false;
    
                that.allSections.push(obj)
            });

            document.querySelectorAll("[data-intersect]").forEach(element => {
                let obj = {};
                const rect = element.getBoundingClientRect();

                obj.offsetTop = rect.top + document.body.scrollTop - document.querySelector("nav").offsetHeight;
                obj.height = element.offsetHeight;
                obj.color = element.getAttribute("data-intersect-color");
                obj.active = false;
                that.sectionsIntersect.push(obj)
            });

        }, 1000)
    }

    componentWillUnmount(){
        window.removeEventListener('scroll', this.handleScroll);
    }

    getMaxHeight(){
        let maxH = 0;
        document.querySelectorAll("nav ul li").forEach(function(elem){
            maxH += elem.offsetHeight
        });
        return maxH + 10 * document.querySelectorAll("nav ul li").length-1;
    }

    getMinHeight(){
        return document.querySelectorAll("nav ul li")[0].offsetHeight;
    }

    handleScroll(event){
        let scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop)
        if (this.lastScroll - scrollTop < 0) {
            this.direction = 'down';
        } else if (this.lastScroll - scrollTop > 0) {
            this.direction = 'up';
        }
    
        this.lastScroll = scrollTop <= 0 ? 0 : scrollTop;
      
        if(scrollTop > window.innerHeight*0.98 - 68)
            document.querySelector("nav").classList.add("fixed");
        else {
            document.querySelector("nav").classList.remove("fixed");
        }

        for (let index = 0; index < this.allSections.length; index++) {
            if(!this.allSections[index].active){
                if(this.direction === 'down' && scrollTop > this.allSections[index].offsetTop){
                    this.allSections[index].active = true;
                    if(this.activeSectionIndex !== index){
                        this.activeSectionIndex = index;
                        this.setSectionActive(this.activeSectionIndex, this.allSections[index].value, this.direction);
                    }
                }
            } else {
                if(this.direction === 'up' && scrollTop < this.allSections[index].offsetTop + this.allSections[index].elemHeight){
                    this.allSections[index].active = false;
                    if(this.activeSectionIndex !== index){
                        this.activeSectionIndex = index;
                        this.setSectionActive(this.activeSectionIndex, this.allSections[index].value, this.direction);
                    }
                }
            }
        }

        for (let index = 0; index < this.sectionsIntersect.length; index++) {
            if(!this.sectionsIntersect[index].active){
                if(this.direction === 'down' && scrollTop > this.sectionsIntersect[index].offsetTop && scrollTop < this.sectionsIntersect[index].offsetTop + this.sectionsIntersect[index].height){
                    this.sectionsIntersect[index].active = true;
                    document.querySelector("nav").classList.remove("white-background");
                    document.querySelector("nav").classList.remove("black-background");
                    document.querySelector("nav").classList.remove("black-and-white-background");
                    document.querySelector("nav").classList.add(`${this.sectionsIntersect[index].color}-background`);
                }
            } else {
                if(this.direction === 'up' && scrollTop < this.sectionsIntersect[index].offsetTop + this.sectionsIntersect[index].height){
                    this.sectionsIntersect[index].active = false;
                    document.querySelector("nav").classList.remove("white-background");
                    document.querySelector("nav").classList.remove("black-background");
                    document.querySelector("nav").classList.remove("black-and-white-background");
                    document.querySelector("nav").classList.add(`${this.sectionsIntersect[index].color}-background`);
                }
            }
        }
    }

    setSectionActive(index, elem, direction){
        let data = this.state.menuItems;
        let clone = data[0];
        if(direction === 'down'){
            data.shift();
            data.push(clone)
        } else {
            data.pop();
            data.unshift(elem);
        }
        this.setState({
            menuItems: data
        });
    }

    clickMenuItem = (elem, i) => {
        let scrollValue = 0;
        for (let index = 0; index < this.allSections.length; index++) {
            if(elem === this.allSections[index].value){
                scrollValue = this.allSections[index].offsetTop + 69
            }
        }

        window.scrollTo({
            top: scrollValue,
            behavior: 'smooth'
        });
        return this.state.menuItems;
    }

    clickNav(){
        const navUl = document.querySelector("nav ul");
        if(!this.state.openedMenu){
            this.setState({
                openedMenu: true
            });
            TweenMax.to(navUl, 1, {height: this.state.maxHeight, ease: Elastic.easeOut.config(1, 1)})
        } else {
            this.setState({
                openedMenu: false
            });
            TweenMax.to(navUl, 1, {height: this.state.minHeight, ease:Elastic.easeOut.config(1, 1.5)})
        }
    }

    render(){
        return(
            <React.Fragment>
                <nav onClick={this.clickNav} className={this.props.backgroundColor}>
                    <div className="hamburguer"></div>
                    <ul>
                        {this.state.menuItems.map((elem, i) => {
                            return <li key={i} onClick={this.clickMenuItem.bind(SubMenu, elem, i)}>{elem}</li>
                        })}
                    </ul>
                </nav>
            </React.Fragment>
        )
    }
}
import React from 'react'
import SubTitle from "../components/sub-title"
import { Link } from "gatsby"
import { TweenMax, Expo } from 'gsap';

export default class PreFooter extends React.Component{

    constructor(props){
        super(props);
        this.handleScroll = this.handleScroll.bind(this);
        this.offsetTop = 0;
        this.inViewport = false;
    }

    componentDidMount(){
        this.offsetTop = document.querySelectorAll(".sub-brand-container")[0].getBoundingClientRect().top;
        window.addEventListener('scroll', this.handleScroll);

        TweenMax.set(".sub-brand-container .sub-brand-container-image .element-cover", {
            scale: 1.4
        })
    }

    handleScroll(event){
        let scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
        if (this.lastScroll - scrollTop < 0) {
            this.direction = 'down';
        } else if (this.lastScroll - scrollTop > 0) {
            this.direction = 'up';
        }
    
        this.lastScroll = scrollTop <= 0 ? 0 : scrollTop;

        if(scrollTop + window.innerHeight > this.offsetTop + window.innerHeight/4 && !this.inViewport){
            this.inViewport = true;
            TweenMax.staggerTo(".sub-brand-container .sub-brand-container-image .element-cover", 1.4,{
                scale:1,
                ease: Expo.easeOut
            }, 0.1)
        }
    }

    render(){
        return(
            <section className="pre-footer" data-intersect data-intersect-color="black-and-white">
                <div className="row expanded align-center">
                    <div className="xxlarge-12 medium-14 columns">
                        <div className="row expanded align-center">
                            <div className="xxlarge-9 xsmall-14">
                                <SubTitle content="Check the other ZIQQI sub-brands guidelines."/>
                            </div>
                        </div>
                        <div className="row expanded xxlarge-up-2 xsmall-up-1">
                            {this.props.subBrands.map((elem, key) => {
                                return(
                                    <div className="columns" key={key}>
                                        <Link to={elem.url}>
                                            <div className="sub-brand-container">
                                                <div className="sub-brand-container-image">
                                                    <div className="block-bg-cover">
                                                        <img className="element-cover" src={elem.imageURL} alt={elem.title}/>
                                                    </div>
                                                </div>
                                                <h3>{elem.title}</h3>
                                                <p>{elem.text}</p>
                                            </div>
                                        </Link>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

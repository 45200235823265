import React from 'react'
import SubTitle from "../components/sub-title"
import Text from "../components/text"
import Fullscreen from "../components/fullscreen"

export default class BrandIntro extends React.Component {
    render(){
        return(
            <section className={this.props.class} data-target data-value={this.props.value} data-intersect data-intersect-color="black">
                <div className="row expanded align-middle">
                    <div className="xxlarge-8 xxlarge-offset-2 medium-10 xsmall-offset-1 xsmall-14 columns">
                        <SubTitle content={this.props.subtitle}/>
                        <Text content={this.props.text}/>
                    </div>
                </div>
                {(this.props.url !== undefined) ? <Fullscreen url={this.props.url} /> : ''}
            </section>
        )
    }
}


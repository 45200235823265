import React from 'react';
import Button from "../components/button"
import { TweenMax, Expo } from 'gsap';


export default class BrandLogo extends React.Component {
    constructor(props){
        super(props);
        this.handleScroll = this.handleScroll.bind(this);
        this.inViewport = false;
        this.brandLogoArray = [];
    }

    componentDidMount(){
        const that = this;
        window.addEventListener('scroll', this.handleScroll);

        document.querySelectorAll(".main-svg").forEach(elem => {
            let obj = {};

            obj.elem = elem;
            obj.offsetTop = elem.getBoundingClientRect().top + window.innerHeight/2;
            obj.inViewport = false;

            if(window.innerWidth > 1024){
                TweenMax.set(elem.querySelector("svg"), {
                    scale: 0.85,
                    opacity: 0
                })
            } else {
                TweenMax.set(elem.querySelector("svg"), {
                    opacity: 0
                })
            }

            that.brandLogoArray.push(obj);
        });
    }

    handleScroll(event){
        let scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
        if (this.lastScroll - scrollTop < 0) {
            this.direction = 'down';
        } else if (this.lastScroll - scrollTop > 0) {
            this.direction = 'up';
        }
    
        this.lastScroll = scrollTop <= 0 ? 0 : scrollTop;

        for (let index = 0; index < this.brandLogoArray.length; index++) {
            if(scrollTop + window.innerHeight > this.brandLogoArray[index].offsetTop && !this.brandLogoArray[index].inViewport){
                this.brandLogoArray[index].inViewport = true;
                if(window.innerWidth > 1024){
                    TweenMax.to(this.brandLogoArray[index].elem.querySelector("svg"), 0.7, {
                        scale: 1,
                        opacity: 1,
                        ease: Expo.easeOut
                    }, 0.1)
                } else {
                    TweenMax.to(this.brandLogoArray[index].elem.querySelector("svg"), 0.7, {
                        opacity: 1,
                        ease: Expo.easeOut
                    }, 0.1)
                }
            }
        }
    }

    render(){
        return(
            <div className={`${this.props.mainClass} brand-logo-container`} style={{backgroundColor: `${this.props.background}`}} data-target>
                <h2 style={{color: `${this.props.color}`}}>{this.props.title}</h2>
                <div className="main-svg">{this.props.pathSvg}</div>
                <Button name="Download the kit" caption={null} href="" class={`${this.props.classButton} ziqqi-button no-position`}></Button>
            </div>
        )
    }
}
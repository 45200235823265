import React from "react";

// a basic form
const SimpleForm = ({ status, message, className, onSubmitted, change }) => {
  let input;
  const submit = () =>
    input &&
    input.value.indexOf("@") > -1 &&
    onSubmitted({
      EMAIL: input.value
    });

  return (
    <div className={className}>
        <div className="form">
            <input
                ref={node => (input = node)}
                type="email"
                placeholder="Enter your e-mail address"
                onChange={change}
            />
            <button onClick={submit} className="disabled">+</button>
        </div>
      <div className="result">
        {status === "sending" && <div className="sending-sentence">Sending...</div>}
        {status === "error" && (
            <div 
            className="error-sentence"
            dangerouslySetInnerHTML={{ __html: message }}
            />
        )}
        {status === "success" && (
            <div 
                className="success-sentence"
            dangerouslySetInnerHTML={{ __html: message }}
            />
        )}
      </div>
    </div>
  );
};

export default SimpleForm;